.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  background-color: #d0454500;
  min-width: 340px;
  width: 70%;
  color: white;
  height: 450px;
}

.footer-text {
  font-weight: 400;
  border-style: none;
  margin-bottom: 20px;
  font-size: 35px;
  color: #ffffff;
}

.footer-design {
  background-color: #00d9ff;
  margin-bottom: -162px;
  margin-left: -15px;
  height: 160px;
  width: 10px;

}

.footer-right .footer-logo {
  width: 100px;
  /* Adjust as necessary */
  height: auto;
}

/* Add responsiveness */
@media (max-width: 600px) {
  .footer {
    flex-direction: column;
  }

  .footer-left,
  .footer-center,
  .footer-right {
    margin: 5px 0;
  }
}