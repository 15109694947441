  .double-marquee-container-client {
    background-color: #f6f6f60f;
    position: relative;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
  }
  
  .overlay-client {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right, 
      rgba(35, 35, 35, 1) 0%, 
      rgba(35, 35, 35, 0) 20%, 
      rgba(35, 35, 35, 0) 80%, 
      rgba(35, 35, 35, 1) 100%
    );
    pointer-events: none; /* Allows clicks to pass through to the content below, if desired */
  }

  .contribution-bubble-client {
    background-color: #ffffff;
    color: white;
    justify-content: center;
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    border-style: solid;
    border-color: #00d9ff;
    border-width: 2px;
    max-height: 150px;
    min-width: 170px;
    font-size: 25px;
    margin-right: 100px;
    white-space: normal;
    display: flex;
    align-items: center;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }

  .marquee-client {
    animation: scroll 50s linear infinite;
    display: flex;
    background-color: #1979df00;
    align-items: center;
    width: 200px;
    width: auto;
  }