.App {
  text-align: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #232323;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.container {
  display: flex;
  flex-direction: row;
  background-color: #a8535300;
  max-width: 1300px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.text-content {
  background-color: #225dd200;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.welcome-message {
  background-color: #69cd2600;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding-left: 20px;
  padding-top: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 35px;
  color: rgb(255, 255, 255);
  height: auto;
  margin-top: 0px;
}

.text-content button {
  background-color: #00d9ff;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 20px;
  margin-top: 40px;
  border-style: none;
  color: #272727;
  cursor: pointer;
  font-size: 20px;
  width: 180px;
  height: 50px;
}

.text-content button:hover {
  background-color: #FEA789;
}

.image-container {
  background-color: #b8d22200;
  display: grid;
  place-items: center;
  left: 0px;
  /* Moves the image 50px to the left */
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Creates 2 columns of equal width */
  gap: 30px;
  /* Adjust the gap between projects as needed */
  max-width: 1300px;
  width: 100%;
  /* Use 100% width for better responsiveness */
  justify-content: center;
  padding: 20px;
  background-color: rgba(44, 135, 209, 0);
  margin-left: 40px;
  margin-right: 40px;
  margin: 0 auto;
}

@media (max-width: 799px) {
  .image-container {
    display: none;
  }

  .container {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns when below 1024px */
    margin-left: 0px;
  margin-right: 0px;
  }
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .projects-container {
    grid-template-columns: 1fr; /* Switches to a single column on small screens */
    margin-left: 0px;
    margin-right: 0px;
  }
}
