.project {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.199);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto; /* Adjusted for consistent spacing around projects */
  border-radius: 20px;
  background-color: #5b5b5b70;
  border-style: solid;
  border-color: #00d9ff;
  min-width: 200px;
  max-width: 500px;
  padding-bottom: 30px;
  border-width: 2px;
}
  .title {
   background-color: rgba(165, 60, 60, 0);
   text-align: left;
   margin-top: 20px;
   margin-left: 22px;
   margin-right: 22px;
   color: #ffffff;
   margin-bottom: 10px;
   font-weight: 600;
   font-size: 22px;
  }

  .main-container {
    display: flex;
    height: 100%;
    margin-left: 22px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-color: #2c941c00;
    justify-content: left;
  }

  .container-img {
    display: flex;
    align-items: center;
    background-color: #ac857700;
    width: 100%;
  }

  .project img {
    width: 100%;
    height: 210px;
    background-color: #38239500;
    border-radius: 18px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .description {
    background-color: rgba(194, 194, 194, 0);
    margin-left: 2px;
    color: #ffffff;
    flex-grow: 1;
    font-size: 20px;
  }

  .button-link {
    font-size: 20px;
    margin-top: auto;
    font-weight: bold;
    margin-top: 10px;
    color: #232323; /* Button text color */
    background-color: #FEA789;
    text-align: center;
    margin-left: 50px;
    margin-right: 50px;
    text-decoration: none; /* Removes underline from links */
    border-radius: 10px; /* Button border-radius */
    border: none; /* Removes border */
    cursor: pointer; /* Ensures cursor changes to pointer on hover */
    line-height: 52px;
    height: 55px;
  }

  .button-link:hover {
    background-color: #00d9ff;
  }